/**=====================
     02. Generic CSS Start
==========================**/

/*====== Padding css starts ======*/
$i:0;
@while $i<=50 {
  .p-#{$i} {
    padding:#{$i}px;
  }
  $i:$i+5;
}
/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i:0;
@while $i<=50 {
  .p-l-#{$i} {
    padding-left:#{$i}px;
  }
  $i:$i+5;
}
/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i:0;
@while $i<=50 {
  .p-t-#{$i} {
    padding-top:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Padding-top css ends ======*/


/*====== Padding-bottom css starts ======*/
$i:0;
@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i:0;
@while $i<=50 {
  .p-r-#{$i} {
    padding-right:#{$i}px;
  }
  $i:$i+5;
}
/*====== Padding-right css ends ======*/


/*====== Margin css starts ======*/
$i:0;
@while $i<=50 {
  .m-#{$i} {
    margin:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i:0;
@while $i<=50 {
  .m-t-#{$i} {
    margin-top:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i:0;
@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i:0;
@while $i<=50 {
  .m-l-#{$i} {
    margin-left:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i:0;
@while $i<=50 {
  .m-r-#{$i} {
    margin-right:#{$i}px;
  }
  $i:$i+5;
}
/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/
$i:0;
@while $i<=10 {
  .b-r-#{$i} {
    border-radius:#{$i}px !important;
  }
  $i:$i+1;
}
/*====== Border-radius css ends ======*/


/*====== Font-size css starts ======*/
$i:12;
@while $i<=100 {
  .f-#{$i} {
    font-size:#{$i}px;
  }
  $i:$i+2;
}
/*====== Font-size css ends ======*/


/*====== Font-weight css starts ======*/
$i:100,
300,
400,
600,
700,
900;
@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}
/*====== Font-weight css ends ======*/


/*====== Font-style css starts ======*/
$i:normal,
italic,
oblique,
initial,
inherit;
@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}
/*====== Font-style css ends ======*/


/*====== Text-Decoration css starts ======*/

$i:overline,
line-through,
underline,
dashed,
blink,
dotted,
initial,
none,
solid,
wavy,
inherit,
double;

@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/*====== Text-Decoration css ends ======*/


/*====== Vertical-Align css starts ======*/
$i:baseline,
sub,
super,
top,
text-top,
middle,
bottom,
text-bottom,
initial,
inherit;
@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}
/*====== Vertical-Align css ends ======*/


/*====== Position css starts ======*/

$i:static,
absolute,
fixed,
relative,
initial,
inherit;
@each $val in $i {
  .p-#{$val} {
    position: $val;
  }
}
/*====== Position css ends ======*/

/*====== Float css starts ======*/
$i:left,
right,
none;
@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}
/*====== Float css ends ======*/


/*====== Overflow css starts ======*/
$i:hidden,
visible,
auto;
@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}
/*====== Overflow css ends ======*/


/*====== Image-sizes css starts ======*/
$i:10;
@while $i<=100 {
  .img-#{$i} {
    width:#{$i}px !important;
  }
  $i:$i+10;
}
/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/
$text-name:
        primary,
        secondary,
        success,
        danger,
        info,
        light,
        dark,
        warning;
$text-color : $primary-color ,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $text-name {
  $i: index($text-name, $var);
  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }
}
/*======= Text css ends ===========*/


/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

$label-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$label-color: $primary-color ,
$primary-color ,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $label-name {
  $i: index($label-name, $var);
  .label-#{$var} {
    background-color: nth($label-color, $i);
  }
}


/*======= Label-color css ends  ======= */


/*======= Badge-color css starts  ======= */

$badge-name:
        primary,
        secondary,
        success,
        danger,
        info,
        light,
        dark,
        warning;
$badge-color:
        $primary-color ,
        $secondary-color,
        $success-color,
        $danger-color,
        $info-color,
        $light-color,
        $dark-color,
        $warning-color;
@each $var in $badge-name {
  $i: index($badge-name, $var);
  .badge-#{$var} {
    background-color: nth($badge-color, $i);
  }
}


/*======= Badge-color css end  ======= */


/*======= Background-color css starts  ======= */

$background-name:
        primary,
        secondary,
        success,
        danger,
        info,
        light,
        dark,
        warning;
$background-color:
        $primary-color ,
        $secondary-color,
        $success-color,
        $danger-color,
        $info-color,
        $light-color,
        $dark-color,
        $warning-color;
@each $var in $background-name {
  $i: index($background-name, $var);
  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
    color: $white;
  }
}


/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */

$text-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning,
google-plus,
twitter,
linkedin,
fb;
$label-color:$primary-color ,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color,
$google-plus,
$twitter,
$github,
$fb;
@each $var in $text-name {
  $i: index($text-name, $var);
  .txt-#{$var} {
    color: nth($label-color, $i) !important;
  }
}
.txt-white{
  color: $white !important;
}

/*======= Font-color css end  ======= */


/*======= Button-color css starts  ======= */
// btn--color//
@each $btn-name, $btn-color in  (primary, $primary-color ),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-#{$btn-name}{
    background-color: $btn-color !important;
    border-color: $btn-color !important;
    @if($btn-name == "light"){
      color: $dark-color !important;
    }
    &.disabled{
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }
    &:disabled{
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }
    &:hover,&:focus,&:active,&.active{
      background-color: darken( $btn-color, 10% ) !important;
      border-color: darken( $btn-color, 10% ) !important;
    }
    &:focus{
      box-shadow: 0 0 0 0.2rem lighten( $btn-color, 30% );
    }
  }
}
/*======= Button-color css ends  ======= */
@each $btn-name, $btn-color in  (primary, $primary-color ),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-outline-#{$btn-name}-2x{
    border-width: 2px;
    border-color:$btn-color;
    color: $btn-color;
    background-color: transparent;
    &:hover,&:focus,&:active,&.active{
      color: white;
      background-color: darken( $btn-color, 10%) !important;
      border-color: darken( $btn-color, 10% )!important;
      box-shadow: none;
    }
  }
}

// outline buttons//
@each $btn-name, $btn-color in  (primary, $primary-color ),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-outline-#{$btn-name}{
    border-color:$btn-color;
    color: $btn-color;
    background-color: transparent;
    &.disabled{
      color: $btn-color;
    }
    @if($btn-name == "light"){
      color: $dark-color;
    }
    &:hover,&:focus,&:active,&.active{
      color: white;
      background-color: darken( $btn-color, 10%) !important;
      border-color: darken( $btn-color, 10% ) !important;
    }
  }
}

//active button css //

@each $btn-name, $btn-color in  (primary, $primary-color ),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-#{$btn-name}:not([disabled]):not(.disabled).active{
    background-color:darken($btn-color,10%);
    border-color:darken($btn-color,10%);
    box-shadow: none !important;
    &:hover,&:focus,&:active,&.active{
      color: white;
      background-color: darken( $btn-color, 10% );
      border-color: darken( $btn-color, 10% );
    }
  }
}

//* active button css end *//

@each $btn-name, $btn-color in  (primary, $primary-color ),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {

  .btn-outline-#{$btn-name}-2x:not([disabled]):not(.disabled).active{
    background-color:$btn-color;
    border-color:$btn-color;
    box-shadow: none !important;
    color: white;
    &:hover,&:focus,&:active,&.active{
      color: white;
      box-shadow: none !important;
      background-color: darken( $btn-color, 10% ) !important;
      border-color: darken( $btn-color, 10% ) !important;
    }
  }
}

/*======= Table-Border-Bottom-color css starts  ======= */

$border-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$border-color:$primary-color ,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $border-name {
  $i: index($border-name, $var);
  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px solid nth($border-color, $i);
  }
}


/*======= Table-Border-Bottom-color css ends  ======= */


/*======= Table styling css starts  ======= */

$table-name:
        primary,
        secondary,
        success,
        danger,
        info,
        light,
        dark,
        warning;
$table-color:$primary-color ,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $table-name {
  $i: index($table-name, $var);
  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: $white;
    border: 3px solid nth($border-color, $i);
    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px solid darken(nth($border-color, $i), 10%);
    }
  }
}


/*======= Table styling css ends  ======= */




/*======= All-Borders-color css starts  ======= */

$b-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$b-color:$primary-color ,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $b-name {
  $i: index($b-name, $var);
  .b-#{$var} {
    border: 1px solid nth($b-color, $i) !important;
  }
  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }
  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }
  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }
  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }
}


/*======= All-Borders-color css ends  ======= */


/*====== Border width css starts ======*/
$i:1;
@while $i<=10 {
  .border-#{$i} {
    border-width:#{$i}px !important;
  }
  $i:$i+1;
}
/*====== Border width css ends ======*/

.opacity-0{
  opacity: 0;
}
.shadow-0{
  box-shadow: none;
}
.map-block{
  height: 300px;
  width: 100%;
}
.map-js-height{
  .map-block {
    position: relative;
    overflow: hidden;

  }
}
.map-block{
  
  .jvectormap-container{
    svg{
      height: 300px;
    }
  }
}
.leaflet-container {
  height: 400px;
  width: 100%;
}
/**====== custom scrollbar css start ======**/
.digits{
  font-family:$font-work-sans, $font-serif;
}
.custom-scrollbar {
  transition: all 3s;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-gray;
  }
  &::-webkit-scrollbar {
    width: 3px;
    transition: .3s;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary-color , 0.20);
  }
}


/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/
.comment{
  color: #9f9ba5;
  font-style: italic;
}
.line{
  color: $dark-color;
}
.line{
  pre{
    font-size: 100%;
  }
}
/**====== Animation css end ======**/

/**====== list style css Start ======**/
.list-circle{
  list-style: circle;
}
/**====== list style css end ======**/

/**====== Modal style css Start ======**/
.theme-close{
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: $white !important;
  border-radius: 5px;
}
/**====== Modal style css end ======**/

/**====== Animation css start ======**/
.options{
  > div{
    color: $gray-60;
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid;
    margin: 0 8px 8px 0;
    transition: all 0.3s ease;
    &:hover{
      background-color: $primary-color ;
      color: $white;
      transition: all 0.3s ease;
    }
  }
}
/**====== Animation css Ends ======**/

.modal-footer{
  flex-wrap: wrap;
}

.img-cropper {
  #putData{
    margin-bottom: 0;
  }
  .img-container{
    min-height: auto;
    margin-bottom: 0;
  }
  .docs-data{
    > .input-group{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .docs-preview{
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.bootstrap-datetimepicker-widget{
  font-family: $font-work-sans, $font-serif;
}
.dropzone {
  .dz-preview {
    .dz-error-message {
      background: $white !important;
      color: $primary-color  !important;
      border: 1px solid $primary-color ;
      &:after{
        border-bottom: 6px solid $primary-color  !important;
      }
    }
  }
}
.typeahead{
  .theme-form {
    .form-group {
      margin-bottom: 0;
    }
  }
}
.datepicker{
   z-index: 99;
}
.irs-from, .irs-to, .irs-single{
  background: $primary-color ;
}
.irs-line-mid, .irs-line-left, .irs-line-right, .irs-bar, .irs-bar-edge {
  background-color: $primary-color ;
}
/**=====================
     02. Generic CSS Ends
==========================**/
.comments {
  margin: 50px auto;
  width: 100%;
  height: 100%;
  border-left: solid 2px #ccc;
  padding: 0px 20px 0px 20px;
}

.comments .actualizacion {
  background-color: #fff;
  padding: 10px;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border: solid 1px #ccc;
  line-height: 1.7;
  position: relative;
  margin-bottom: 20px;
}

.comments .actualizacion::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: $purple-color;
  top: 10px;
  left: -28px;
}

.actualizacion p.lugar{
  font-size: 12px;
  color: #9f9ba5;
}

.btn-purple {
  background-color: $purple-color;
  color: $white;
  border-radius: 100px;
  font-family: $font-urbanist;
  padding: 6px 10px;
}

.btn-purple:hover {
  background-color: $purple-color;
  color: $white;
}


.btn-red {
  background-color: $btn-bhome-bg-c;
  color: $white;
  border-radius: 100px;
  /* font-family: $font-urbanist; */
  padding: 6px 10px;
  &:hover{
      background-color: $btn-bhome-bg-c;
    color: $white;
  }
}

.list-summary-detail{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li{
    margin-bottom: 12px;
    font-weight: 800;
    color: $gray-color;
    width: 100%;
    max-width: 50%;
    span{
      color: $purple-color;
      font-weight: 800;
    }
  }
  li.marked{
    font-size: 18px;
    max-width: 100%;
  }
  li.second{
    max-width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .list-summary-detail{
    li{
      max-width: 100%; 
    }
  }
}

.form-group {
  .btn-purple {
    width: 100%;
    margin-top: 20px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .fa-user {
    color: $green-color;
  }
  .fa-lock {
    color: $green-color;
  }
  .fa-hashtag {
    color: $green-color;
  }
  .input-gpack {
    background-color: rgba(241, 182, 52, .1);
    border-radius: 100px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    
    .input-group-text {
      border: none!important;
      background: none!important;
    }
    
    input {
      background: transparent!important;
      border: none!important;
      color: $purple-color;
      font-family: $font-urbanist;
      font-weight: 700;
    }

    input::placeholder {
      color: $purple-color;
      font-family: $font-urbanist;
      font-weight: 700;
    }

    .icon-input {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.authentication-main {
  background-image: url(../../images/backlogin.jpg)!important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center!important;
  background-color: none!important;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-input {
    background-color: transparent!important;
  }
}

.fa-th {
  font-size: 22px!important;
  color: $green-color;
}
.fa-search {
  color: $white;
}
.card {
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 40px;
  .card-header {
    h4 {
      font-family: $font-urbanist!important;
    }
  }
  .card-body  {
    h6 {
      font-family: $font-urbanist;
      margin-bottom: 10px;
    }
    h4 {
      font-family: $font-urbanist!important;
    }
    h3 {
      font-family: $font-urbanist!important;
    }
    h5 {
      font-family: $font-urbanist!important;
    }
  }

  label {
    font-family: $font-urbanist;
    font-size: 14px;
    font-weight: 500;
  }

  .input-gpack {
    background-color: #F0F9FF;
    border-radius: 100px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid $purple-color;
    
    .input-group-text {
      border: none!important;
      background: none!important;
    }
    
    input {
      background: transparent!important;
      border: none!important;
      color: $purple-color;
      font-family: $font-urbanist;
      font-weight: 500;
    }

    input::placeholder {
      color: $purple-color;
      font-family: $font-urbanist;
      font-weight: 500;
    }

    .icon-input {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $purple-color;
      border-radius: 100px!important;
    }
  }

  .input-gpack2 {
    background-color: rgba(241, 182, 52, .1);
    border-radius: 100px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid $yellow-color;
    
    .input-group-text {
      border: none!important;
      background: none!important;
    }
    
    input, select, #react-list_prod_serv {
      background: transparent!important;
      border: none!important;
      color: $purple-color;
      font-family: $font-urbanist;
      font-weight: 500;
      width: 100%;
    }

    input::placeholder, select::placeholder, #react-list_prod_serv::placeholder {
      color: $purple-color;
      font-family: $font-urbanist;
      font-weight: 500;
    }


    .icon-input {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $purple-color;
      border-radius: 100px!important;
    }
  }
  .card {
    padding: 0;
  }

  .productsboxes {
    .pagin {
      margin-left: auto;
    }
    
    .card {
      margin-top: 30px;

      .product-box {
        .product-img {
          .product-hover {
            background-color: transparent;

            ul {
              li {
                background-color: $green-color;
              }
            }
          }
        }
      }
    }
  }
}

textarea {
  border: 1px solid $yellow-color;
  border-radius: 6px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  background-color: #FEF8EB;
}

.css-13cymwt-control {
  background: transparent!important;
  border: none!important;
}

.btn-green {
  background-color: $green-color;
  color: $white;
}

.btn-green:hover {
  background-color: $green-color;
  color: $white;
}

.elementospagina {
  margin-left: 6px;
  border-radius: 4px;
  border: 1px solid $purple-color;
  color: $purple-color;
}

.elementospagina::placeholder {
  color: $purple-color;
}

.btn-nuevo {
  margin-right: 10px;
}
@media (max-width:1199px) {
  .btn-nuevo {
    margin-right: auto;
  }
}

.btn-regresar {
  background-color: $green-color;
  border-radius: 50px;
  padding: 0px;
  margin: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  .fa-chevron-left {
    font-size: 22px;
  }
}

.btn-back {
  background-color: $gray-color;
  color: $white;
  border-radius: 100px;
}

.theme-tab {
  .tab-title {
    li.react-tabs__tab--selected {
      color: $purple-color!important;
      border-bottom: 2px solid $purple-color!important;
    }
    li {
      font-family: $font-urbanist;
    }
  }
}


.codigos {
  .col-lg-12.col-xl-6 {
    width: 100%;
  }
}

.card-codigos {
  .btn-secondary {
    background-color: #9f9ba5!important;
    border: none!important;
  }
}

.tabla-envio-solicitud {
  td {
    padding: 16px 0 0 0!important;
    border: none;
  }
}

.btn-menos {
  background-color: #F2F2F2;
  padding: 6px!important;
}
.btn-menos:hover {
  background-color: $green-color;
}
.btn-mas {
  background-color: #F2F2F2;
  padding: 6px!important;
}
.btn-mas:hover {
  background-color: $green-color;
}

.filtros-productos {
  .btn-secondary {
    background-color: #9B9B9B!important;
    border: none!important;
  }
}

.deleted-float-filter{
  right: 30px;
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-family: Urbanist;
  /* width: 80px; */
  font-weight: 700;
  color: #ff5370;
  border-radius: 4px;
  padding: 2px 8px;
  transition: all .3s ease-in-out;
  &:hover{
    /* background-color: #ff5370;
    color: #FFFFFF; */
    color: #c42b45;
  }
}

.accordion {
  .accordion-item {
    .title {
      background-color: $purple-color!important;
      color: $white!important;
    }
    .title::after {
      color: $white!important;
    }
  }
}

/* .notie-container {
  width: 70%!important; 
} */


