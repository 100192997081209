/* custom-ckeditor.css */

.ck-editor__main .ck-editor__editable {
    background-color: #fef8ea !important;
    padding: 20px !important;
    border: 1px solid #f1b634 !important;
    border-top: 0 !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

.ck.ck-sticky-panel{
    border: 0px;
}

.ck.ck-sticky-panel__content{
    border: 0px !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
}

.ck-editor__top {
    border: 1px solid #f1b634 !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

.ck.ck-editor__top .ck-toolbar {
    background-color: #fef8ea;
    color: #fff;
}

.ck.ck-editor__top .ck-menu-bar {
    background-color: #fef8ea;
    color: #fff;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
}

.ck .ck-editor__editable_inline{
    font-family: "Montserrat", sans-serif;
}

/* .ck-heading_paragraph{
    font-size: 80px !important;
} */
