// @import "bootstrap.scss";
@import "~bootstrap-scss/bootstrap.scss";
@import "color.scss";
@import "themify.scss";
@import "whether-icon/whether-icon";
@import "flag-icon/flag-icon-base";
@import "flag-icon/flag-icon-more";
@import "flag-icon/flag-icon-list";
@import "icoicon/icons";
@import "font-awesome.scss";
@import "sticky/sticky";
@import "slick.scss";
@import "slick-theme.scss";
@import "~react-toastify/dist/ReactToastify.css";
@import "~@fullcalendar/common/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

// Datepicker
@import "~react-datepicker/dist/react-datepicker.css";

//  ImageCrop
@import "~react-image-crop/dist/ReactCrop.css";

// Mde EDitor
@import "~easymde/dist/easymde.min.css";

// Gallery Light Box
@import "~react-image-lightbox/style.css";

// chat emoji
@import "~emoji-mart/css/emoji-mart.css";

@import "chartist/chartist-settings";
@import "chartist/chartist";

@import '@asseinfo/react-kanban/dist/styles.css';

@import "~react-perfect-scrollbar/dist/css/styles.css";

@import "tabla/tabla.scss";

:root{
    --purple: #5C068C;
    --green: #84BD00;
    --white: #ffffff;/* f8f8f8 */
    --gray: #545861;
    --gray-2: #cccccc;
    --black: #0d0d0d;
    --yellow: #F1B634;
    --red: #ff1d1d;

    --transition-all-03: all 0.3s ease-in-out;
}

.accordion .accordion-item.active .panel {
	max-height: 100% !important;
	-webkit-transition: max-height 0.25s ease-in !important;
	-o-transition: max-height 0.25s ease-in !important;
	transition: max-height 0.25s ease-in !important;
  }

body{
    .notie-container {
        z-index: 5000;
    }
}

.card-permisos{
    // background-color: aquamarine;
    padding: 1em 0.5em;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-container {
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  .loader-2 {
    width: 280px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 20px solid var(--green);
    border-top-color: transparent; // Hace que solo una parte gire
    animation: spin 1s linear infinite;
  }